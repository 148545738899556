.container {
  min-width: 700px;
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.steps {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px;
}
.step {
  border-radius: 5px;
  width: 9%;
  height: 30px;
  background: rgb(216, 216, 216);
  border: 2px solid rgb(216, 216, 216);
  transition: background 1s;
}
.step.selected {
  border: 2px solid grey;
}
.step.completed {
  border: 2px solid rgb(216, 216, 216);
  background: grey;
}
.progress {
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: rgb(216, 216, 216);
  padding: 5px;
  z-index: -1;
}
