@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

$red: #d40000;
$gray: #5c5d5e;
$gray-light: #d6d4d4;
$green: #189d14;

*,
*:before,
*:after{
  box-sizing: border-box;
}

*:focus{
  outline: none;
}

body{
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-size: 17px;
  background-color: lighten($gray-light, 10);
  color: #000000;
  line-height: 1.7;
  a{
    transition: all 0.25s ease-in-out;
    text-decoration: none;
    cursor: pointer;
  }
  img{
    max-width: 100%;
  }
  h1,
  h2,
  h3,
  h4{
    line-height: 1.2;
  }
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    &:first-child {
      margin-top: 0 !important;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  p{
    text-align: justify;
    margin: 20px 0;
    &.large {
      font-size: 22px;
    }
    &.center{
      text-align: center;
    }
  }
  h2{
    text-align: center;
    font-size: 50px;
    color: $gray;
    margin: 50px 0;
    &.left{
      text-align: left;
    }
  }
  .wrap {
    max-width: 100%;
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
  .button-container {
    text-align: center;
    margin-top: 40px;
    .button:not(:first-child){
      margin-left: 40px;
    }
  }

  .button {
    border-width: 0;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-size: 17px;
    background: $red;
    color: #ffffff;
    transition: background-color 0.3s, color 0.3s;
    border-color: $red;
    border-radius: 40px;
    font-weight: bold;
    display: inline-block;
    padding: 1em 2em;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    line-height: 1.2;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $gray;
      z-index: -1;
      border-radius: inherit;
      opacity: 0;
      transform: scale3d(0.6, 0.6, 1);
      transition: transform 0.3s, opacity 0.3s;
      transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
      background: #ffffff;
    }

    &:hover {
      background-color: #ffffff;
      color: $gray;

      &::before {
        transform: scale3d(1, 1, 1);
        opacity: 1;
      }
    }
    &.green{
      border-color: $green;
      background-color: $green;
    }
    &.fixed{
      width: 250px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    .col {
      padding-left: 20px;
      padding-right: 20px;
    }
    .col-2 {
      width: 50%;
    }
    .col-auto {
      flex: 1;
    }
  }
  .layout{
    min-height: 100vh;
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    > .wrap{
      flex: 1;
      display: flex;
      align-items: center;
      padding-top: 100px;
      padding-bottom: 100px;
      > div:not(.row){
        width: 100%;
      }
      .image{
        margin-top: -100px;
        max-height: calc(100vh - 220px);
        display: block;
        &.no-margin{
          margin-top: 0;
        }
      }
      .col-fixed{
        max-width: 40%;
      }
    }
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    background-color: #FFFFFF;
    padding: 15px 0;
    .wrap{
      display: flex;
      align-items: center;
    }
    .logo {
      display: block;
      line-height: 0;
      img {
        height: 40px;
        display: block;
      }
    }
    .title{
      margin-left: 50px;
      color: $gray;
      font-weight: bold;
    }
  }
  form{
    label:first-child{
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 15px;
      margin-bottom: 10px;
    }
    .form_row {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    input,
    select,
    textarea {
      font-family: "Lato", sans-serif;
      font-size: 16px;
      transition: background-color 0.25s ease-in-out;
    }
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="search"],
    input[type="date"],
    select,
    textarea {
      display: block;
      width: 100%;
      border: 0px;
      padding: 10px 15px;
      background-color: #ffffff;
      border-radius: 5px;
    }
    .input_error{
      font-size: 12px;
      color: $red;
      margin-top: 5px;
    }
  }
  .box {
    padding: 30px;
    border-radius: 5px;
    background: #ffffff;
    border-bottom: 2px solid $red;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    form{
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="search"],
      input[type="date"],
      select,
      textarea,
      input[type="checkbox"] + label::before {
        border: 1px solid $gray-light;
      }
    }
  }
  footer{
    line-height: 1.4;
    background: $gray;
    font-size: 14px;
    color: $gray-light;
    text-align: center;
    padding: 15px 0;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  body {
    .wrap {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  body {
    .row .col {
      width: 100%;
      flex: none;
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
    .box{
      width: 75%;
    }
  }
}

@media (max-width: 1023px) {
  body{
    .layout > .wrap{
      .image{
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        max-height: 50vh;
      }
      .col-fixed{
        max-width: none;
        .image{
          max-width: 60%;
        }
      }
    }
  }
}

@media (max-width: 767px){
  body{
    h2{
      font-size: 35px;
    }
    .wrap {
      padding-left: 15px;
      padding-right: 15px;
    }
    .button{
      padding-left: 1.75em;
      padding-right: 1.75em;
    }
    p.large{
      font-size: 21px;
    }
    .row {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      .col {
        width: 100%;
        flex: none;
        padding-left: 15px;
        padding-right: 15px;

        &:not(:first-child) {
          margin-top: 30px;
        }
      }
    }
    .box {
      padding: 20px;
      width: 100%;
    }
    .button-container{
      .button{
        &:not(:first-child){
          margin-left: 0;
          margin-top: 30px;
        }
      }
    }
    .layout > .wrap{
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}