.box.mail {
  margin-top: 50px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.mail button {
  box-sizing: border-box;
  margin-top: 0;
  width: 5px;
  border-color: lightgray;
  border-width: 1px;
  font-size: small;
  min-width: 20px;
  width: max-content;
  padding: 2px;
  text-transform: lowercase;
}

.attachment {
  cursor: pointer;
  width: 180px;
  height: 120px;
  border: 1px solid #eee;
  border-radius: 2px;
  position: relative;
  margin-top: 10px;
}

.attachment img {
  width: 178px;
  height: 85px;
  overflow: clip;
}

.attachmentFooter {
  width: 100%;
  height: 35px;
  position: absolute;
  bottom: 0;
  background-color: #fafafa;
  border-top: 1px solid #eee;
}

.attachmentFooter :first-child {
  width: 16px;
  height: 16px;
  background-color: #f94e39;
  margin-top: 9px;
  margin-left: 10px;
  background-image: url(../../public/pdf-icon.png);
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: -999px;
  display: inline-block;
  float: left;
}

.attachmentFooter .filename {
  margin-top: 11px;
  padding-left: 0px;
  margin-left: 10px;
  font-size: 13px;
  float: left;
}

.attachmentFooter .fold {
  content: "";
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 40px;
  height: 20px;
  background-image: url(../../public/attachment-fold.png);
  background-size: 100% auto;
  z-index: 1;
}

.mailInfo {
  display: flex;
  gap: 20px;
  color: gray;
}

.senderData {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.senderData p {
  margin: 0;
}

.senderData :first-child {
  font-weight: 700;
  color: black;
}

.modal {
  display: none;
  position: "absolute";
  z-index: 1;
  left: 20px;
  top: 65px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.visible {
  display: block;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

.modalContent {
  background-color: #fff;
  position: "absolute";
  padding: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  width: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.modalRow {
  display: flex;
  gap: 10px;
  line-height: 0;
}

.rowLabel {
  width: 60px;
  text-align: right;
}

.avatarImage {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background-color: burlywood;
  border-radius: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.avatarImage p {
  font-size: larger;
}

#facebookLogo {
  width: 60%;
}

#allegroLogo {
  width: 100px;
  align-self: flex-start;
  margin: 10px;
}

#allegroBanner {
  width: 100%;
  align-self: center;
}

#content1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

#content2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#content1 button {
  background-color: #0078f6;
  color: white;
  border-width: 0;
  padding: 10px;
  font-size: large;
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

#content2 button {
  background-color: #ff5a00;
  color: white;
  border-width: 0;
  border-radius: 0;
  padding: 15px;
  font-size: medium;
  text-transform: none;
  margin-top: 10px;
  width: 300px;
  margin-bottom: 10px;
}

.mailFooter {
  border-top-width: 1px;
  border-color: gray;
  padding-top: 10px;
}

.mailFooter {
  width: 100%;
  border-top: 2px solid lightgray;
}

p a {
  text-decoration: underline;
  cursor: pointer;
}

.left {
  align-self: flex-start;
  text-align: left;
}

.docImage {
  content: "";
  width: 18px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
  transform: translate(0, 3px);
  background-image: url(../../public/doc-icon.png);
  background-size: 100% auto;
}

#content4 {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.docName {
  display: inline-block;
  font-size: 21px;
  line-height: 1.45em;
  color: #448aff !important;
  font-weight: 400;
  cursor: pointer;
}

.docName a {
  color: #448aff !important;
}

.textFlex {
  display: "flex";
  align-items: center;
}

.userIcon {
  width: 45px;
  height: 45px;
  background-color: #92addc;
  float: left;
  background-image: url(../../public/user-icon.png);
  background-size: 100% auto;
  margin-right: 20px;
  margin-top: 10px;
}

.footerText {
  margin-bottom: 5px;
}

.googleDocsButton {
  background-color: #4d90fe;
  border: 1px solid #3079ed;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  line-height: 29px;
  min-width: 54px !important;
  font-size: 15px !important;
  font-weight: 400;
  padding: 2px 12px !important;
  letter-spacing: 0.05em;
  text-transform: none !important;
  margin-top: 30px !important;
  cursor: pointer;
}

#content5 {
  margin-top: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f5f5f5;
}

#content5 .footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  font-size: 12px;
  line-height: 1.8em;
  color: #616161;
  text-align: center;
}

.footer .logo {
  display: block;
  margin: 0 auto 10px;
  width: 100px;
}

.infoBox {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}

.margin20 {
  margin-top: 20px !important;
}

#content6 {
  padding: 30px;
  width: 100%;
}

#content7 {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#content7 .header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

#microsoftLogo {
  width: 20%;
}

.grayBox {
  background-color: #faf9f8;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#warning {
  width: 60px;
}

.center {
  text-align: center;
}

.center a {
  color: #5d7fd8;
}

#microsoftButton {
  font-family: "Segoe UI Semibold", wf_segoe-ui_semibold, "Segoe UI", "Segoe WP",
    Tahoma, Arial, sans-serif;
  line-height: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 20px 13px 20px;
  background-color: #0078d4;
  border: 1px solid #0078d4;
  display: inline-block;
  text-transform: none;
}

.redText {
  color: red !important;
  font-weight: 800;
}

.redButton {
  background-color: red !important;
}

#redDocName {
  color: red !important;
  font-weight: 800;
}

.grayText {
  color: gray !important;
  font-weight: 400 !important;
}

table {
  border: 1px solid black;
  font-size: 11pt;
  padding: 5px 5px 5px 5px;
  text-align: center;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

th {
  border: 1px solid black;
  font-size: 11pt;
  text-align: center;
}

td {
  border: 1px solid black;
  font-size: 11pt;
  padding: 5px 5px 5px 5px;
  text-align: center;
}

.dropBoxIcon {
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto 15px;
  background-image: url(../../public/dropbox-icon.png);
  background-size: 85% auto;
  background-repeat: no-repeat;
  background-position: center;
}

.dropboxButton {
  clear: both;
  background-color: #007ee6;
  border: 1px solid #3079ed;
  border-radius: 10px;
  color: #fff !important;
  display: inline-block;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  line-height: 29px;
  min-width: 54px;
  outline: 0px;
  font-size: 15px;
  font-weight: 400;
  padding: 6px 80px;
  letter-spacing: 0.05em;
  text-align: center;
  text-decoration: none;
}

.dropBoxFooter {
  background-color: rgb(227, 227, 227);
  width: 100%;
  padding: 20px;
  margin-top: 30px;
}

#content10 {
  padding:20px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  margin: auto;
  color: #3c3c3c;
  background-color: rgb(255, 255, 255);
  line-height:1.2;
}

#inpostLogo {
  align-self: flex-end;
  margin-right: 20px;
}

.face {
  width:300px;
}

.divider{
  background-color: rgb(216, 216, 216);
    height: 1px;
}

.inpostButton{
  font-family: Montserrat,Arial,sans-serif;
    width: 95%;
    background-color: #ffcb04;
    color: #3c3c3c;
    font-weight: bold;
    padding: 20px;
    margin-bottom: 20px;
}

.inpostYellowText{
  margin-top: 10px;
  color:#ffcb04;
  font-weight: 700;
}

#qr{
  margin: 0 auto;
}

.codeData{
  padding: 20px 20px;
    font-family: 'Montserrat',sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background-color: rgb(60, 60, 60);
}